@import url("../node_modules/@fortawesome/fontawesome-free/css/all.min.css");
:root {
  --mentorOrange: #f38b32;
  --mentorGreen: #067c4b;
}
html {
  margin: 0;
  border: 0;
  height: 100%;
  box-sizing: border-box;
  padding: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*outline: 1px solid red;*/
  overflow-x: hidden;
  scroll-behavior: smooth;
}
body {
  border: 0;
  margin: 0;
  padding: 0;
  /* background: rgba(229, 229, 229, 0.3); */
}
.menubutton {
  transition: all 1s ease-in;
}
.bg-mentorOrange {
  background-color: var(--mentorOrange);
}
img {
  block-size: auto;
  max-inline-size: 100%;
}
.memberlogin-img {
  width: 98%;
  height: 98%;
  margin: 5px;
  object-fit: contain;
}
/* li {
  list-style: none;
} */
.hero {
  background-color: #f38b32;
  min-width: 100vw;
}
.btn-warning {
  background-color: #f38b32;
}
.btn-warning:hover {
  background-color: #f38b32;
}
.middle
  .dropdown-item
  .btn-member
  .navbuttonspacers
  .btn1
  .small-nav-item
  .usernameSection
  .dropdown-toggle {
  font-size: 16px !important;
}
@media (max-width: 768px) {
  .navbuttonspacers {
    margin-bottom: 0.8rem !important;
  }

  .hero {
    background-color: #f38b32;
    min-width: 100vw;
    /* min-height: 30vh; */
    /* min-height: 100vh; */
    /*background-image: url('./images/hero-sm.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
  }
  .hero-text {
    font-size: 2rem;
    text-align: center;
    padding-top: 3vh;
  }
  .hero-p-text {
    text-align: center;
    padding-top: 3vh;
  }

  .pc-screen-hidden {
    display: none;
  }
  .pc {
    display: none;
  }

  .bt-hero {
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
  }
  /* .btn-hero {
    align-self: center;
  } */

  .black-bg-height {
    min-height: 40vh;
  }
  /* .solutions-section {
    padding-top: 4rem;
  } */
  .blue-bg {
    display: none;
  }
  .smaller-bg {
    display: none;
  }
  .background-img {
    display: none;
  }
  .calc-title-section {
    font-size: 1.2rem;
    font-weight: 600;
    padding-bottom: 1rem;
  }
  .section-paragraph {
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 1.5rem;
  }
  .btn-lg {
    background-color: #f5811e;
    font-weight: 400;
    width: 95%;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: white;
    border: none;
    margin-bottom: 2rem;
  }
  .green-img-circle {
    height: 25%;
    width: 25%;
  }
  .comment {
    font-size: 14px;
    padding: 20px;
    margin-bottom: 100px;
    text-align: left;
  }
  .topimg {
    background-image: url("../src/images/membershipsIMG.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* background-position: bottom -50px; */
    height: 100%;
    width: 100vw;
  }
  .small {
    font-size: 15px;
  }
}

@media (min-width: 767px) {
  .small {
    font-size: 10px;
  }
  .mobile {
    display: none;
  }
  .hero-text-padding {
    padding-top: 10vh;
  }
  .navbuttonspacers {
    margin-right: 0.3rem !important;
  }

  .mobile-screen-hidden {
    display: none;
  }
  .green-img-circle {
    height: 10%;
    width: 10%;
  }

  .hero {
    /* background-color: #fff; */
    min-height: 100vh;
    min-width: 100vw;
    background-image: url("./images/mentor-bg.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-x: hidden;
  }
  .hero-text {
    font-size: 2.5rem;
    height: 100%;
  }
  .hero-p-text {
    max-width: 26rem;
    padding: 2vh 0;
    font-size: 1rem;
    margin-bottom: 20px;
  }
  .paraspacer {
    padding-top: 7rem;
  }
  .bt-hero {
    margin-left: 0.07rem;
    height: 60px;
  }

  .black-bg-height {
    min-height: 50vh;
  }
  .logo {
    padding-top: 10px;
  }
  /* #started {
    font-size: 1.5rem;
    font-weight: 500;
    padding-bottom: 1.5rem;
  } */
  .cursor-pointer {
    cursor: pointer;
  }
  .mentor-orange {
    color: var(--mentorOrange);
  }
  .off-toaster {
  }
  .cursor-pointer:hover {
    color: #067c4b;
  }
  .needs-validation label::before {
    content: "*";
    color: red;
    margin-right: 5px;
  }
  .no-asterisk::before {
    content: "" !important;
  }

  #midname::before {
    content: "";
  }
  #external-info,
  #externalContent,
  #external-contents {
    font-family: "Poppins", sans-serif !important;
  }
  #external-info span,
  #externalContent span,
  #external-contents span {
    font-weight: normal;
    line-height: 1em;
  }
  #subheader {
    font-size: 1.5rem;
    font-weight: 600;
    padding-bottom: 1rem;
  }
  h2#subheader {
    font-size: 1.5rem;
    margin-top: 1rem !important;
  }
  h3#subheader {
    font-size: 1.5rem;
  }
  #subheaderform {
    font-size: 1.2rem;
    font-weight: 600;
    padding: 1rem 0.5rem;
  }

  #subheaderheavy {
    font-size: 1.5rem;
    font-weight: 700;
  }
  #subheaderheavy2 {
    font-size: 1.8rem;
    font-weight: 600;
  }
  #subheader2 {
    font-size: 1.5rem;
    font-weight: 600;
  }
  /* .solutions-section {
    padding-top: 4rem;
  } */
  .branches-section {
    padding-top: 3rem;
  }
  .mentoring-section {
    padding-top: 2rem;
  }
  .mentoring-paragraph {
    font-family: "Poppins" !important;
    font-size: 1rem;
  }

  /* .service-btn{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .service-btn:hover{
    background-color: rgba(0, 0, 0, 0.075);
    border-radius: 8px;
  } */

  .ownpiece-p {
    max-width: 80%;
  }
  .header-text {
    padding-bottom: 1.5rem;
  }
  .mt-1r {
    margin-top: 1rem;
  }
  .mt-2r {
    margin-top: 2rem;
  }
  .mt-3r {
    margin-top: 3rem;
  }
  .mt-4r {
    margin-top: 4rem;
  }
  .mb-4r {
    margin-bottom: 4rem;
  }
  .mb-1r {
    margin-bottom: 1rem;
  }
  .mt-5r {
    margin-top: 5rem;
  }
  .mb-5r {
    margin-bottom: 5rem;
  }
  .mb-2r {
    margin-bottom: 2rem;
  }
  .pt-4r {
    padding-top: 4rem;
  }
  .calc-title-section {
    font-size: 32px;
    font-weight: 600;
    padding-bottom: 1rem;
  }
  .btn-lg {
    background-color: #f5811e;
    font-weight: 400;
    width: 95%;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: white;
    border: none;
  }
  .comment {
    font-size: 19px;
    padding: 20px;
    margin-bottom: 100px;
    text-align: left;
  }

  /* .mentoring-paragraph {
    max-width: 90%;
    justify-content: center;
    align-items: center;
  } */
}

.image-info {
  position: absolute;
  display: flex;
  z-index: 100;
  color: #fff;
  bottom: 0;
  left: 250px;
  right: 250px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.green {
  color: #067c4b;
  font-weight: 500;
}
.orange {
  color: #f5811e;
  font-weight: 600;
}
.arrow-blue {
  color: #4482c3;
}

.green-color {
  background-color: #067c4b;
}

.btn {
  background-color: #067c4b;
  font-weight: 400;
  padding: 7px 30px;
  border-radius: 5px;
  color: white;
}
.btn2 {
  font-weight: 400;
  padding: 7px 10px;
  border-radius: 5px;
  color: white;
}
.btn-transparent {
  background-color: transparent;
  font-weight: 400;
  font-size: 21px;
  width: 95%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #f5811e;
  border: 1px solid #f5811e;
}
.btn-transparent-form {
  background-color: transparent;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #f8a762;
  border: 1px solid #f8a762;
}
.btn-transparent-white {
  background-color: transparent;
  font-weight: 400;
  /* width: 95%; */
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #fff;
  border: 1px solid #fff;
}
.btn-transparent-small {
  background-color: transparent;
  font-weight: 400;
  /* width: 95%; */
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  color: #000;
  border: 1px solid #000;
}
.btn-transparent-small-orange {
  background-color: transparent;
  font-weight: 400;
  /* width: 95%; */
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  color: #f5811e;
  border: 1px solid #f5811e;
}
.btn-transparent-small-green {
  background-color: transparent;
  font-weight: 400;
  /* width: 95%; */
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  color: rgba(6, 124, 75, 1);
  border: 1px solid rgba(6, 124, 75, 1);
}

.btn-outline-success {
  /* background-color: #049F40; */
  color: white;
  background-color: #067c4b;
  font-weight: 400;
  /* padding: 0 10px; */
}

.btn-outline-success:hover {
  background-color: #f8a762;
  color: white;
  border-color: transparent;
  font-weight: 600;
  /* background-color: #198754; */
}

.btn-outline-primary {
  /* background-color: #049F40; */
  color: white;
  background-color: #f8a762;
  font-weight: 600;
  border: #f8a762;
}

.btn-outline-primary:hover {
  background-color: #067c4b;
  color: white;
  border-color: transparent;
  font-weight: 600;
  /* background-color: #198754; */
}
.btn-outline-primary:focus {
  background-color: #067c4b;
  color: white;
  border-color: #067c4b;
  font-weight: 600;
  /* background-color: #198754; */
}
.btn-member {
  background-color: #f5811e;
}
.white-header {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: space-evenly;
  align-items: center;

  position: relative;
  top: 20px;
}
.mentor-sign {
  width: 100%;
}
.mentor-sign-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  background: beige;
}
.mentor-sign-btn button {
  width: 50%;
  border-radius: 3px;
  outline: none;
}

.w-half {
  width: 50%;
}

@media (max-width: 480px) {
  .w-half {
    width: 100%;
  }
  .mentor-sign-btn {
    flex-direction: column;
    gap: 1rem;
  }
  .mentor-sign-btn button {
    width: 100%;
  }
  .mentor-sign-btn button:first-child {
    order: 2;
  }
}
/* .navbar-buttons {
  background: blue;
  /padding-bottom: 10px
} */
/* .solutionslinks a{
  font-family: 'Poppins', sans-serif;
  color: #067C4B;
  font-weight:600;
}
.solutionslinks a:hover{
  font-family: 'Poppins', sans-serif;
  color: #F5811E;
  font-weight:600;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-weight: 800;
}
h2 {
  font-weight: 700;
}
h3 {
  font-weight: 600;
}

p {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
}

.comment-owner {
  font-size: 14px;
  margin-left: 10px;
  margin-top: 10px;
  padding-bottom: 20px;
  width: 30vw;
  /*  padding: 0 10px 20px 20px; */

  text-align: left;
}
.orange-lite-button {
  background: rgba(254, 210, 164, 0.3);
  border: 1px solid #f5811e;
  border-radius: 7px;
  line-height: 2.5rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
}

.pink-lite-button {
  background-color: #ffd7e6;
  border: 0.15rem solid #ff7bac;
  border-radius: 0.3rem;
  line-height: 2.5rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
}

.orange-lite-button:hover {
  background-color: #f8a762;
}

.blue-lite-button {
  background: rgba(216, 236, 254, 0.3);
  border: 1px solid #3097f4;
  border-radius: 0.3rem;
  line-height: 2.5rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
}

.white-dark-button {
  background-color: #f6f6f7;
  border: 0.15rem solid #dbdddd;
  border-radius: 0.3rem;
  line-height: 2.5rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
}

.green-lite-button {
  background: rgba(91, 208, 102, 0.3);
  border: 1px solid #5bd066;
  border-radius: 0.3rem;
  line-height: 2rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
}

.orange-lite-button:hover {
  background-color: #f8a762;
}

.blue-lite-button:hover {
  background-color: #a6bff6;
}

.white-dark-button:hover {
  background-color: #dbdddd;
}

.green-lite-button:hover {
  background-color: #5bd066;
}

/* lite background colors */
.orange-lite {
  background-color: #fef2e9;
}

.pink-lite {
  background-color: #ff7bac;
}

.blue-lite {
  background-color: #e9effd;
}

.white-dark {
  background-color: #f6f6f7;
}

.green-lite {
  background-color: #e6f2ed;
}
/* lite background colors */

.black-link a {
  color: black;
  text-decoration: none;
  font-size: 1.1rem;
}

.herolinks {
  padding-top: 0.9rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 0.3rem;
}

.herolinks:hover {
  background-color: #f8a762;
}

.message-background {
  /* min-height: 40vh; */
  margin: 20px;
  /* background-image: url('./images/messageback.svg');
    background-position: center center;
    background-repeat: no-repeat; */
  /* background-attachment: fixed; */
  background-color: #fde0c7;
  background-size: cover;
  border-radius: 1rem;
  position: relative;
}

.message-background-pc {
  min-height: 40vh;
  background-image: url("./images/messageback.svg");
  background-position: center center;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-size: cover;
  border-radius: 0.5rem;
}

.message-background-mobile {
  min-height: 40vh;
  background-image: url("./images/messageback-sm.svg");
  background-position: center center;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-size: cover;
  border-radius: 0.5rem;
}

.own-piece-section {
  min-height: 80vh;
  background-image: url("./images/lady-green-tshirt.svg");
  background-position: center center;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-size: cover;
  border-radius: 0.5rem;
  padding-top: 5rem;
  padding-left: 2rem;
}

.own-piece-section-mobile {
  min-height: 40vh;
  background-image: url("./images/lady-green-tshirt.svg");
  background-position: center center;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-size: cover;
  border-radius: 0.5rem;
  padding-top: 2rem;
}

/* footer */
footer {
  /* background-color: #515354; */
  background: #067c4b;
  color: white;
  z-index: 1000;
}

.copyright {
  background-color: #f5811e;
}

footer li {
  list-style-type: none;
}
footer a {
  color: white;
  text-decoration: none;
}

footer a:hover {
  color: #f5811e;
  text-decoration: none;
}

.quote-builder {
  min-height: 60vh;
  background-image: url("./images/quotebuilder.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}

.form-check-input:checked {
  background-color: #067c4b;
  border-color: #067c4b;
}

/* .home-background{


} */
/* .main {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  min-height: 100vh;
} */
.section {
  /* min-height: 100vh;  */
  width: 100%;
  background-color: #fff;
  /* scroll-snap-align: start;
  scroll-padding: 40%; */
}
.sectionMain {
  min-height: 100vh;
  width: 100%;
  background-color: #fff;
  /* scroll-snap-align: start;
  scroll-padding: 40%; */
}
.section-op {
  min-height: 100vh;
  position: relative;
  /* scroll-snap-align: start;
  scroll-padding: 40%; */
}
.section-contact {
  /* max-height: 100vh;  */
  position: relative;
  overflow: hidden;
  margin-top: 3rem;
}
.section-s {
  /* max-height: 100vh;  */
  min-height: 100vh;
  position: relative;
  /* overflow: hidden; */
}
/* .solutions {
  height: 150vh;
} */
.background-img {
  position: absolute;
  bottom: -180px;
  left: 0;
}
.blue-bg {
  position: absolute;
  right: -130px;
  bottom: 130px;
}
.smaller-bg {
  position: absolute;
  left: 0;
  top: -100px;
}
.quote-img {
  position: absolute;
  top: -25px;
  left: 20px;
  z-index: 10000;
}
.green-img {
  position: absolute;
  bottom: 10px;
  left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  /* width: 10vw; */
}
.section-footer {
  /* max-height: 80vh; */
  width: 100vw;
  scroll-snap-align: start;
  scroll-padding: 40%;
  z-index: 1000;
}

select,
input,
textarea {
  background-color: #f6f6f7 !important;
  font-weight: bold;
}

select::-webkit-input-placeholder,
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #263238 !important;
  font-weight: bold !important;
  opacity: 0.5 !important;
}

select::-moz-placeholder,
input::-moz-placeholder,
textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #263238 !important;
  font-weight: bold !important;
  opacity: 0.5 !important;
}

select:-ms-input-placeholder,
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #263238 !important;
  font-weight: bold !important;
  opacity: 0.5 !important;
}

select:-moz-placeholder,
input:-moz-placeholder,
textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #263238 !important;
  font-weight: bold !important;
  opacity: 0.5 !important;
}

.loans-background {
  min-height: 60vh;
  background-image: url("./images/backgrounds/money.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.form-check-input:checked {
  background-color: #f5811e !important;
  border-color: #f5811e;
}

main {
  min-height: 50vh;
}

/* FAQ */
.accordion-button:not(.collapsed) {
  color: black;
  background-color: #e7f1ff;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.accordion-button {
  background-color: #fff9f4 !important;
}

.accordion-button:active {
  border: 0px !important;
}

.collapsed {
  background-color: #f3f8f6 !important;
}
/* FAQ */

/* Button box shadow */

.accordion-button:focus {
  border-color: transparent;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #f5811e;
}
/* Button box shadow */

/* Location */
.location {
  min-height: 25vh;
  background-image: url("./images/locations/muranga.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0.3rem;
}
.location,
a {
  text-decoration: none;
}
.location:hover {
  background-color: #f5811e;
}

.bg-location {
  background-color: #067c4b;
  backdrop-filter: brightness(0.5);
  border-radius: 0.3rem;
  z-index: 0;
  min-height: 25vh;
  min-width: 100%;
  color: black;
}
.bg-location:hover {
  background-color: white;
  border: solid 6px #067c4b;
  color: #067c4b;
}
.bg-location,
a {
  text-decoration: none;
}
/* location */

/* Registration Steps */
.navtabs {
  text-decoration: none;
  font-weight: bold;
}

.navtabs a {
  color: white;
  text-decoration: none;
}

.navtabs a:hover {
  color: white;
  text-decoration: none;
}

.disabled {
  color: #067c4b;
  text-decoration: none;
  font-weight: bold;
}

.step-color {
  background-color: #fde0c7;
  color: #067c4b;
}

.step-color a {
  background-color: #fde0c7;
  color: #067c4b;
}

.step-color-current {
  background-color: #067c4b;
}

.step-number {
  /* min-height: 92vh; */
  background-image: url("./images/icons/white_icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #067c4b;
}

.step-number-current {
  background-image: url("./images/icons/green_icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}
/* Registration Steps */

a {
  color: #f5811e;
}

/* Modal */
@media (min-width: 768px) {
  .custom-modal {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    background-color: white;
    padding: 1rem;
    text-align: center;
    width: 30rem;
    z-index: 10;
    position: fixed;
    top: 20vh;
    left: calc(50% - 15rem);
  }
  .product-tabs {
    color: #000;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
  }

  .product-tabs-active {
    color: #067c4b;
    font-weight: 700;
    text-align: center;
  }

  .product-tabs-active:after {
    content: "";
    background-color: #067c4b;
    width: 100%;
    height: 2px;
    border-radius: 5px;
    display: block;
    margin-top: 1rem;
    align-self: center;
  }
  .topimg {
    background-image: url("../src/images/mentormemberships.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right 0px bottom -600px;
    height: 400px;
    width: 100vw;
  }
}
@media (max-width: 767px) {
  .custom-modal {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    background-color: white;
    padding: 1rem;
    text-align: center;
    width: 20rem;
    z-index: 10;
    position: fixed;
    top: 20vh;
    left: calc(50% - 10.5rem);
  }
  .product-tabs {
    color: #000;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
  }

  .product-tabs-active {
    color: #067c4b;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
  }

  .product-tabs-active:after {
    content: "";
    background-color: #067c4b;
    width: 100%;
    height: 2px;
    border-radius: 5px;
    display: block;
    margin-top: 1rem;
    align-self: center;
  }
}
.backdrop {
  position: fixed;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

/* Modal */

.bg-news {
  background: rgba(81, 163, 129, 0.1);
  margin-top: 3rem;
  /* height: 50vh; */
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  padding-left: 3rem;
}
@media (max-width: 480px) {
  .bg-news {
    flex-direction: column;
  }
}
.news-card {
  height: 70vh;
}
.event-banner {
  height: auto;
  width: 100%;
}

/* Profile Tabs User */
.profile-tabs {
  /* background-color: #067C4B; */
  color: #067c4b;
  /* border: 1px solid #067C4B; */
}

.product-tab-div {
  border-bottom: 1px solid #494949;
  width: 82vw;
}

.profile-tabs:hover {
  /* background-color: white; */
  color: #f5811e;

  /* border: 1px solid #067C4B; */
}

/* .border-right{
  border-right: 1px solid #c4c4c4;
} */

/* Profile Tabs User */

/* About Us */
.about-bg {
  background-image: url("./images/mentor-sacco-complex.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 70vh;
  /* background-color: #2A5A88; */
}
.breadcrumb-div {
  --bs-breadcrumb-divider: ">";
  padding-top: 10px;
}
.breadcrumb-item-override {
  color: #000;
}
.breadcrumb-item-override-active {
  color: #067c4b;
}

.line {
  display: block;
  width: 100%;
  height: 0.5px;
  background-color: rgba(0, 0, 0, 0.6);
}
/* Forms */
.form-error {
  color: rgba(252, 25, 25, 0.8);
}
.news-title {
  font-weight: 500;
  font-size: 20px;
  line-height: "25%";
  padding: 7px 0px;
}
.news-title-date {
  font-weight: 300;
  font-size: 16px;
  padding: 5px 0px;
}
.article-p {
  font-weight: 400;
  line-height: 35px;
  font-size: 16px;
  padding: 20px 0px;
}

.article-highlight-p {
  font-style: italic;
  font-weight: 400;
  line-height: 35px;
}
.article-highlight-div {
  padding: 3px 10px;
  border-left: 3px solid #067c4b;
  height: auto;
}
.morenews {
  background-color: rgba(6, 124, 75, 0.2);
  padding: 3rem 0rem;
}
.link-black {
  color: #000;
}
.landingpage-img1 {
  background-image: url("./images/userlangingpage/image1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 35vh;
  border-radius: 10px;
}
.landingpage-img2 {
  background-image: url("./images/userlangingpage/image2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 35vh;
  border-radius: 10px;
}
.bottom-img {
  position: absolute;
  bottom: 1px;
  /*background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 115.99%);*/
  align-items: center;
  width: 96%;
  height: 10vh;
  color: #fff;
  border-radius: 10px;
  filter: brightness(1);
}
.bottom-img-sm {
  position: absolute;
  bottom: 1px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 115.99%);
  align-items: center;
  width: 94.5%;
  height: 10vh;
  color: #fff;
  border-radius: 10px;
  filter: brightness(1);
}
.active-detstab {
  border: 1px solid #e5e4e2;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
}

.photo {
  width: 244px;
  height: 190px;
  background: #c4c4c4;
  display: flex;
  justify-content: center;
  align-items: center;
}
.usernameSection {
  background-color: rgba(229, 229, 229, 0.5);
  padding: 8px;
  /* width: 30%; */
  border-radius: 8px;
  display: flex;
  align-items: center;
}

#username {
  color: rgba(0, 0, 0, 0.6);
  padding-right: 10px;
}

.serviceCard {
  height: 40vh;
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding-bottom: 20px;
  filter: brightness(0.8);
  transition: all 0.5s ease-in-out;
}
.serviceCard:hover {
  transform: translate3D(0, -1px, 0) scale(1.03);
  box-shadow: 8px 28px 50px rgba(39, 44, 49, 0.07),
    1px 6px 12px rgba(39, 44, 49, 0.04);
  filter: brightness(1);
  transform: scale(1.1);
}

.event-card {
  cursor: pointer;
  width: 330px;
  padding: 1rem;
}

.event-card:hover {
  transform: translate3D(0, -1px, 0) scale(1.03);
  box-shadow: 8px 28px 50px rgba(39, 44, 49, 0.07),
    1px 6px 12px rgba(39, 44, 49, 0.04);
}
.detdivider {
  border-left: 1px solid #e5e4e2;
  width: 1px;
}
.photoSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.detailssection {
  display: flex;
  justify-content: center;
  align-items: center;
}
.box {
  width: 300px;
  margin: 50px auto;
  border: 4px solid #067c4b;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  font-weight: 900;
  color: #00bfb6;
  position: relative;
  background-color: #067c4b;
}
.sb1:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
  border-bottom: 10px solid #067c4b;
  right: 50%;
  top: -23px;
}
.sb1:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
  border-bottom: 10px solid #067c4b;
  background-color: #067c4b;
  right: 50%;
  top: -18px;
}

.bubble {
  position: absolute;
  top: 80px;
  right: 1%;
  background: #067c4b;
  color: #ffffff;
  font-family: Arial;
  font-size: 20px;
  padding: 20px;
  width: 400px;
  /* height: 120px; */
  border-radius: 10px;
  z-index: 20;
  position: fixed;
}
@media (max-width: 480px) {
  .bubble {
    max-width: 480px;
    width: 90%;
    left: 5%;
    top: 100px;
  }
}
@media (max-width: 767px) {
  .bubble {
    max-width: 768px;
    width: 90%;
    left: 5%;
    top: 130px;
  }
}

.bubble:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: #067c4b transparent;
  border-width: 0 20px 20px;
  top: -20px;
  left: 50%;
  margin-left: -20px;
}

.open-position {
  padding: 20px;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
}

button.close {
  background-color: #fff;
  border: 0 none !important;
  color: #067c4b;
  display: inline-block;
  float: left;
  font-size: 34px;
  height: 40px;
  line-height: 1;
  margin: 0px 1px;
  opacity: 1;
  text-align: center;
  text-shadow: none;
  -webkit-transition: background 0.2s ease-in-out;
  transition: background 0.2s ease-in-out;
  vertical-align: top;
  width: 46px;
}
.chat-bot {
  position: absolute;
  bottom: 0;
  right: 0;
  position: fixed;
}

.error-message {
  color: red;
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

.csr {
  background-image: url("./images/csr/maincsr.png");
  background-repeat: no-repeat;
  background-position: 60%;
  background-size: cover;
  width: 100%;
  position: relative;
  height: 60vh;
}
.csr-body {
  background-color: #067c4b;
  align-items: center;
  text-align: center;
  color: #fff;
  height: 50vh;
}
.display-left {
  color: #fff;
  /* transform: translate(5%,220%); */
  padding-top: 20vh;
}
.contact {
  /* height: 50vh; */
  background: rgba(245, 129, 30, 1);
}
#testimonial .client-img {
  width: 80px;
  height: 80px;
  overflow: hidden;
  border: 3px solid #fff;
  margin: 0px auto;
  /* border-radius: 100%; */
  position: absolute;
  left: 0px;
}
#testimonial .carousel-content {
  padding: 20px 0px 20px 100px;
  width: 70%;
  margin: 0 auto;
  position: relative;
}
#testimonial h3 {
  font-size: 17px;
  color: #fff;
  margin-bottom: 30px;
  font-style: italic;
  text-align: right;
}
#testimonial p {
  font-size: 15px;
}
#testimonial .client-img img {
  width: 100%;
}
#testimonial .carousel-control-prev,
#testimonial .carousel-control-next {
  font-size: 36px;
}

@media (max-width: 576px) {
  #testimonial .carousel-content {
    padding: 20px 0px 20px 0px;
    width: 100%;
  }
  #testimonial .client-img {
    margin: 20px auto;
    position: static;
  }
  #testimonial h3,
  #testimonial p {
    text-align: center;
  }
}
.mycard {
  border-left-width: 5px;
  border-left-color: rgba(245, 129, 30, 1);
  background: rgba(126, 208, 158, 1);
}
.newstitlep {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.newstitlep:hover {
  overflow: visible;
}
.contact_header {
  background-image: url("./images/contactplaceholder.jpg");
  height: 350px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 100vw;
}
.contact_content {
  height: 350px;
  background: #00000099;
  opacity: 0.7;
  font-size: 36px;
  font-weight: 600;
  color: #ffffff;
  width: 100vw;
}
.contents {
  position: absolute;
  top: 30%;
  left: 25%;
  right: 25%;
  width: 50%;
}
.control {
  background-color: transparent !important;
  border: 1px solid #c2bdbd;
  box-sizing: border-box;
  border-radius: 8px;
}
.address {
  font-weight: 500;
  color: rgb(110, 106, 106);
}
.contact-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
}
.contact-header {
  background: rgba(245, 129, 30, 1);
  color: #ffffff;
  /* height: 30vh; */
}
@media screen and (max-height: 600px) {
  .address {
    margin-top: 50px;
  }
  .contents {
    position: absolute;
    top: 0;
  }
  .contact-header {
    width: 100%;
    height: 60vh;
  }
  .contact-footer {
    margin-left: 50px;
  }
}
.img-fluid {
  max-inline-size: 100%;
  block-size: auto;
  object-fit: cover;
}

.forgot {
  cursor: pointer;
  color: #067c4b;
}
.btn {
  transition: all 0.25s ease-in;
}
.forgot:hover {
  text-decoration: underline;
  color: #f5811e;
}
.box-g {
  box-shadow: -11px 10px 5px -6px rgba(0, 0, 0, 0.29);
  -webkit-box-shadow: -11px 10px 5px -6px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: -11px 10px 5px -6px rgba(0, 0, 0, 0.29);
}
.thin-border {
  border-right: 1px solid #ccc;
}
.status-tab {
  border-right: 1px solid #ccc;
  border-top: 1px solid #ccc;
}
.status-tab:first-child {
  border-left: 1px solid #ccc;
}
@media (max-width: 480px) {
  .table-container {
    overflow-x: scroll;
    border-right: 2px solid #ccc;
    z-index: 100;
  }
  .thin-border {
    border-bottom: 1px solid #ccc;
  }
  .csr-img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    height: auto;
  }
  .csr {
    height: 15vh;
  }
  .display-left {
    color: #fff;
    /* transform: translate(5%,220%); */
    padding-top: 2vh;
    font-size: 0.5rem;
  }
  .small {
    font-size: 10px;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .table-container {
    overflow-x: scroll;
    border-right: 2px solid #ccc;
    z-index: 100;
  }
  .csr-img {
    object-fit: cover;
    object-position: top left;
    height: auto;
  }
  .csr {
    height: 30vh;
  }
  .display-left {
    color: #fff;
    /* transform: translate(5%,220%); */
    padding-top: 10vh;
  }
  .small {
    font-size: 1rem;
  }
}

/*PAGINATION*/
.containerClassName {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
}
.containerClassName li {
  list-style: none;
}
.activeClassName {
  background-color: var(--mentorOrange);
}

.pageClassName {
  background: var(--mentorGreen);
  color: white;

  padding: 0 0.5rem;
  width: 25px;
  height: 25px;

  border-radius: 50%;
  margin: 0 0.5rem;
}
.team-container {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
}

.team-container > div {
  max-width: 320px;
  max-height: 320px;

  overflow-y: hidden;
}

@media (max-width: 480px) {
  .team-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .team-container > div {
    max-width: 100%;
    max-height: 320px;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .team-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

.table-container img {
  height: 100%;
}

.position-relative {
  position: relative;
}
.team-caption {
  font-weight: 500;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 8px 0;
  transition: all 300ms ease-in-out;
}
.team-caption:hover {
  background: var(--mentorGreen, rgba(0, 0, 0, 0.8));
}
